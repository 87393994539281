<template>
  <GlPageWrap
    class="pp-page__monitoring"
    hide-title-on-mobile
    title="Monitoring"
  >
    <template #actions>
      <div class="m-pa-2 m-fixed-bottom-wrapper pp-page__monitoring-actions">
        <GlButton
          class="m-fullwidth"
          dark
          title="Add to monitoring"
          @click="showAddModal = true"
        >
          <template #beforeTitle>
            <gl-icon
              class="mr-2"
              height="20"
              name="pp-add"
              width="20"
            />
          </template>
        </GlButton>
      </div>
    </template>
    <div class="pp-monitoring__header">
      <div class="pp-monitoring__header-coin-select">
        <GlCoinSelect
          dark
          small
          @change="handleChangeCoin"
        />
      </div>
      <div class="pp-monitoring__header-search">
        <gl-search-box
          v-model="address"
          button-text="Search"
          dark-clear
          :disabled="loading || !address"
          grey
          height="32px"
          :independent="isMobileInclude ? true : false"
          is-search
          placeholder="Enter the wallet address"
          @clear="clearSearching"
          @search="searching"
        />
      </div>
      <div class="pp-monitoring__header-requests">
        <gl-requests-counter />
      </div>
    </div>
    <div class="pp-monitoring__content">
      <o-table
        class="pp-table"
        :data="addressData"
        hoverable
        :mobile-cards="false"
      >
        <o-table-column
          v-slot="props"
          field="riskScore"
          label="Score"
        >
          <div class="pp-monitoring__table-score">
            <GlSpiner
              v-if="props.row.riskScore === undefined || props.row.riskScore === null"
            />
            <div v-else>
              <GlScoreLevelChip
                :level="props.row.riskScore ? Math.ceil(props.row.riskScore) : 0"
                score
                small
              />
            </div>
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="address"
          label="Address"
        >
          <div
            class="link pp-monitoring__table-address"
            @click="openInNewTab(props.row.address)"
          >
            {{ trancateString(props.row.address, isSmallDevice ? 12 : 4) }}
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="profileFlags"
          label="Profile Flags"
          :visible="featureAccess('ALERTS')"
        >
          <div class="pp-monitoring__table-profile-flags">
            <GlFlagsWrap
              aggregate
              :flags-data="props.row.alertsListData"
              :max-count="3"
            />
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="firstSeen"
          label="First seen"
        >
          <div
            class="pp-monitoring__table-first-seen"
            v-html="props.row.firstSeen ? formatDate(props.row.firstSeen * 1000, 'dd.MM.yyyy HH:mm', true) : '-'"
          />
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="updatedAt"
          label="Last seen"
        >
          <div
            class="pp-monitoring__table-last-seen"
            v-html="props.row.lastSeen ? formatDate(props.row.lastSeen * 1000, 'dd.MM.yyyy HH:mm', true) : '-'"
          />
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="txCount"
          label="No. of TXs"
        >
          <div class="pp-monitoring__table-number-of-txs">
            {{ props.row.txCount ? txsAddPlus(props.row.txCount, 2000002) : '-' }}
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="balance"
          label="Balance"
        >
          <div class="pp-monitoring__table-balance">
            <div>
              {{ props.row.balance && props.row.balance.amount ? toComaSeparate(restrictNumberAfterComma(props.row.balance.amount, 8)) + " " + props.row.balance.symbol :
                props.row.balance && props.row.balance.amount == 0 ? 0 : '-' }}
            </div>
            <div
              v-if="props.row.balance && props.row.balance.price || props.row.price"
              v-tooltip.top.ios="{content: priceMessage('current', props.row.priceTimestamp)}"
              class="pp-monitoring__table-balance-price"
            >
              ({{ formatByPrice(props.row.balance.price || props.row.price) }})
            </div>
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="amountReceived"
          label="Amount received"
          :visible="coinData.family !== 'eth'"
        >
          <div class="pp-monitoring__table-amount-received">
            <div>
              {{ props.row.amountReceived ? toComaSeparate(restrictNumberAfterComma(formatBtcAmount(props.row.amountReceived, false, coinData.family, false, true), 8)) + " " + props.row.balance.symbol :
                props.row.amountReceived == 0 ? 0 : '-' }}
            </div>
            <div
              v-if="props.row.amountReceivedPrice"
              v-tooltip.top.ios="{content: priceMessage('current', props.row.amountReceivedPriceTimestamp)}"
              class="pp-monitoring__table-amount-received-price"
            >
              ({{ formatByPrice(props.row.amountReceivedPrice) }})
            </div>
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="cntrls"
          label=""
        >
          <div class="pp-monitoring__table-cntrls">
            <gl-icon
              v-if="featureAccess('REPORT')"
              v-tooltip.top="'Risk report'"
              class="pp-monitoring__table-cntrls-open-report"
              :height="16"
              link
              name="open-on-new-page"
              :width="16"
              @click="openInNewTab(props.row.address, 'report')"
            />
            <gl-menu-item
              v-tooltip.top="'Delete address'"
              class="pp-monitoring__table-cntrls-delete"
              icon="delete-responsive"
              :icon-height="16"
              :icon-width="15"
              warn
              @click="setRemoveAddress(props.row.address)"
            />
          </div>
        </o-table-column>
        <template #empty>
          <div
            class="empty-users-data flex column align-center"
          >
            <gl-icon
              :height="24"
              name="statistic"
              :width="24"
            />
            No data here yet
          </div>
        </template>
      </o-table>
      <div class="pp-pagination-table">
        <div class="pp-pagination-table__left">
          <div class="pp-pagination-table__text">
            Rows per page:
          </div>
          <div class="pp-pagination-table__select">
            <vSelect
              v-model="perPage"
              class="select--top-direction"
              :clearable="false"
              :options="pagesOptions"
              :searchable="false"
              @input="countChange"
            />
          </div>
        </div>
        <div class="pp-pagination-table__right">
          <o-pagination
            v-if="addressData.length && totalPages > 1"
            :current.sync="currentPage"
            :per-page="perPage"
            :range-after="0"
            :range-before="0"
            :total="total"
            @change="pageChange"
          >
            <template #default="props">
              <div
                v-if="props.page.isCurrent"
                class="pp-pagination-table__pages"
              >
                {{ `${props.page.number} of ${totalPages}` }}
              </div>
            </template>
            <template #previous="props">
              <div class="pp-pagination-table__button-prev">
                <o-pagination-button
                  :page="props.page"
                >
                  <gl-menu-item
                    :disabled="props.page.disabled"
                    icon="left"
                    :icon-height="24"
                    :icon-width="24"
                    label=""
                    not-outline
                  />
                </o-pagination-button>
                <div class="pp-pagination-table__current">
                  {{ props.page.number + 1 }}
                </div>
              </div>
            </template>
            <template #next="props">
              <div class="pp-pagination-table__button-next">
                <o-pagination-button
                  :page="props.page"
                >
                  <gl-menu-item
                    :disabled="props.page.disabled"
                    icon="right"
                    :icon-height="24"
                    :icon-width="24"
                    label=""
                    not-outline
                  />
                </o-pagination-button>
              </div>
            </template>
          </o-pagination>
        </div>
      </div>
      <div
        v-show="loading"
        class="pp-monitoring__loader"
        style="z-index: 10"
      >
        <gl-loader />
      </div>
    </div>
    <AddToMonitoringModal
      v-if="showAddModal"
      v-model="showAddModal"
      @add="handleAddNewAddress"
      @add-bulk="uploadBulkHandler"
      @close="showAddModal = false"
    />
    <BulkUploadResult
      v-if="showAddBulkModal"
      v-model="showAddBulkModal"
      :data="addBulkData"
      @close="showAddBulkModal = false"
    />
    <StopMonitoringModal
      v-if="stopMonitoringModal"
      v-model="stopMonitoringModal"
      :address="removedAddress"
      @close="stopMonitoringModal = false"
      @submit="removeAddress"
    />
  </GlPageWrap>
</template>

<script>
// Components
import vSelect from 'vue-select';
import GlIcon from '@/components/gl-icon';
import GlLoader from '@/components/gl-loader';
import GlSpiner from '@/components/gl-spiner';
import GlButton from "@/components/gl-button";
import GlMenuItem from '@/components/gl-menu-item';
import GlSearchBox from "@/components/gl-search-box";
import GlPageWrap from "@/components/layout/gl-page-wrap";
import GlCoinSelect from "@/components/gl-coin-select.vue";
import GlScoreLevelChip from '@/components/gl-score-level-chip';
import GlFlagsWrap from '@/components/gl-flags-wrap';
import BulkUploadResult from "@/pages/monitoring/modals/BulkUploadResult";
import StopMonitoringModal from "@/pages/monitoring/modals/StopMonitoringModal";
import AddToMonitoringModal from "@/pages/monitoring/modals/AddToMonitoringModal";

// Utils
import { formatDate } from "@/utils/format-date";
import { featureAccess } from "@/utils/accesses";
import { trancateString } from '@/utils/text-formatter';
import { formatBtcAmount } from '@/utils/format-btc-amount';
import { restrictNumberAfterComma, toComaSeparate } from '@/utils/formatNumber';
import { formatByPrice, priceMessage } from "@/utils/format-by-price";
import { txsAddPlus } from '@/utils/txs-add-plus'
import { tokensSorting, getNativeCoinIcon } from "@/utils/cytoskape-ui-rules";

// Vuex
import { mapActions, mapState } from "vuex";
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import { AlertsMixin } from '@/assets/mixins/alertsMixin'
import appConfig from '@/utils/appConfig'
import GlRequestsCounter from '@/components/gl-requests-counter.vue'

export default {
  components: {
    GlRequestsCounter,
    GlIcon,
    vSelect,
    GlButton,
    GlLoader,
    GlSpiner,
    GlPageWrap,
    GlMenuItem,
    GlSearchBox,
    GlCoinSelect,
    GlScoreLevelChip,
    BulkUploadResult,
    StopMonitoringModal,
    AddToMonitoringModal,
    GlFlagsWrap,
  },
  mixins: [deviceWidthMixin, AlertsMixin],
  data() {
    return {
      address: '',
      enableSearchParams: false,
      showAddModal: false,
      showAddBulkModal: false,
      addBulkData: null,
      loading: false,
      pagesOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 20,
      totalPages: 1,
      removedAddress: '',
      stopMonitoringModal: false,
      total: 1,
      addressData: [],
      restoreAddressData: [],
      monitoringCalculateHandler: null,
      addressesCalculateHandler: null,
      counter: {},
      tempData: {},
      addressRequestsMap: {},
      timeoutMonitoringList: null,
      timeoutMonitoringScore: null,
    }
  },
  computed: {
    ...mapState('analytics', ['coinData', 'coinType'])
  },
  created() {
    this.timeoutMonitoringList = appConfig.VUE_APP_TIMEOUT_MONITORING_LIST
    this.timeoutMonitoringScore = appConfig.VUE_APP_TIMEOUT_MONITORING_SCORE
    this.monitoringGettingInterval()
    this.loadData()
  },
  beforeDestroy() {
    clearTimeout(this.monitoringCalculateHandler);
    clearTimeout(this.addressesCalculateHandler);
  },
  beforeRouteLeave(from, to, next) {
    clearTimeout(this.monitoringCalculateHandler);
    clearTimeout(this.addressesCalculateHandler);

    this.addressRequestsMap = {};

    next()
  },
  methods: {
    featureAccess,
    trancateString,
    formatBtcAmount,
    formatDate,
    restrictNumberAfterComma,
    formatByPrice,
    toComaSeparate,
    priceMessage,
    txsAddPlus,
    tokensSorting,
    getNativeCoinIcon,
    ...mapActions({
      getAddressesMonitoring: 'monitoring/getListSubscriptions',
      getAddressesMonitoringScore: 'monitoring/getAddressesMonitoring',
      deleteAddressFromMonitoring: 'monitoring/deleteAddressFromMonitoring',
      getAlertDataByAddress: 'alerts/getAlertDataByAddress',
      getAlertsByAddress: 'alerts/getAlertsByAddress',
      getAlertsByIds: 'alerts/getAlertsByIds',
      getAddressTokens: 'analytics/getAddressTokens',
      getAddressMetrics: 'analytics/getAddressMetrics',
    }),
    handleChangeCoin() {
      clearTimeout(this.monitoringCalculateHandler);
      clearTimeout(this.addressesCalculateHandler);
      this.addressRequestsMap = {}
      this.monitoringGettingInterval()
      this.loadData()
    },
    searching() {
      this.enableSearchParams = true
      this.loadData()
    },
    clearSearching() {
      this.enableSearchParams = false
      this.loadData()
    },
    monitoringGettingInterval(interval = this.timeoutMonitoringList) {
      if (this.$route.name === 'monitoring') {
        this.monitoringCalculateHandler = setTimeout(() => {
          this.loadData(false).finally(() => this.monitoringGettingInterval(interval))
        }, interval);
      }
    },
    getScoreInterval(item) {
      if (this.$route.name === 'monitoring') {
        this.addressesCalculateHandler = setTimeout(() => {
          this.getScoreByAddress(item)
        }, this.timeoutMonitoringScore);
      }
    },
    setRemoveAddress(address) {
      this.removedAddress = address
      this.stopMonitoringModal = true
    },
    removeAddress(address) {
      if (address) {
        this.deleteAddressFromMonitoring({ address }).then(({ success }) => {
          if (success) {
            this.stopMonitoringModal = false
            this.loadData()
          }
        }).catch(({ response: { data } }) => {
          this.$toasted.global.error({message: `${data.data.message}`})
        })
      }
    },
    handleAddNewAddress() {
      this.currentPage = 1
      this.loadData()
    },
    async loadData(isNeedLoading = true) {
      if (isNeedLoading) {
        this.loading = true
      }
      const sendParams = this.formattedSendData()

      if (sendParams.address && !new RegExp(this.coinData.addressRegex).test(sendParams.address)) {
        this.$toasted.global.error({message: `Incorrect search value`})
        this.loading = false
        this.enableSearchParams = false
        return
      }

      await this.getAddressesMonitoring(sendParams).then(({ data, success }) => {
        if (!success) {
            this.loading = false
            clearTimeout(this.monitoringCalculateHandler);
        }

        if (Array.isArray(data.list)) {
          this.addressData = data.list.map((item) => {
            const findIndex = this.restoreAddressData.findIndex(el => el.address === item.address)
            const prepareItemData = {
              ...item,
              ...(this.coinData.family === 'eth' && {
                txCount: null,
                firstSeen: null,
                lastSeen: null
              })
            };


            if (findIndex === -1) {
              return prepareItemData
            } else {
              return {
                ...prepareItemData,
                ...this.restoreAddressData[findIndex],
                alertsListData: this.restoreAddressData[findIndex] && this.restoreAddressData[findIndex].alertsListData ? [...this.restoreAddressData[findIndex].alertsListData] : []
              }
            }
          })
        } else {
          this.addressData = []
        }

        this.total = data.totalItems || 0
        this.totalPages = Math.ceil(this.total / this.perPage)

        const requestedAddress = this.addressData.map(async (item) => {
          if (!this.addressRequestsMap[item.address]) {
            this.addressRequestsMap[item.address] = true;
            await this.getScoreByAddress(item);
          }
        });

        Promise.allSettled(requestedAddress)

        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    async getScoreByAddress(item) {
      if (this.$route.name === 'monitoring'){
        this.getAddressesMonitoringScore({ address: item.address }).then(async ({ data }) => {
          let updatedData = data.list[0];

          if (this.coinData.family === 'eth') {
            const { data: { seen, totalTxs }, success } = await this.getAddressMetrics({ address: item.address, contract: '', type: this.coinData.family === 'eth' ? 'tokens' : 'main' })

            if (success) {
              updatedData.txCount = totalTxs.total
              updatedData.firstSeen = seen.first
              updatedData.lastSeen = seen.last
            }
          }

          this.setRestoringData(updatedData)

          const index = this.addressData.findIndex(el => el.address === data.list[0].address);

          this.addressRequestsMap[item.address] = false;

          if (this.featureAccess('ALERTS') && new RegExp(this.coinData.addressRegex).test(item.address)) {
            this.loadAlerts(item.address).then((getAlertsByIdsData) => {
              const filteredAlerts = getAlertsByIdsData.filter(alert => alert.count > 0);

              this.$set(this.addressData[index], 'alertsListData', filteredAlerts);

              const restoreIndex = this.restoreAddressData.findIndex(el => el.address === data.list[0].address);
              if (restoreIndex !== -1) {
                this.$set(this.restoreAddressData[restoreIndex], 'alertsListData', filteredAlerts);
              }
            });
          }

          if (index !== -1) {
            this.$set(this.addressData, index, {
              ...this.addressData[index],
              ...updatedData,
              alertsListData: this.addressData[index].alertsListData || []
            });
          }

          if (data.list[0].riskScore === undefined || this.addressData[index].riskScore !== data.list[0].riskScore) {
            this.addressData[index].loading = true;
            this.getScoreInterval(item);
          } else {
            this.addressData[index].loading = false;
          }
        });
      }
    },
    setRestoringData(data) {
      if (data.address) {
        const index = this.restoreAddressData.findIndex(item => item.address === data.address)

        if (index === -1) {
          this.restoreAddressData.push(data)
        } else if (this.restoreAddressData[index].riskScore !== data.riskScore || this.restoreAddressData[index].txCount !== data.txCount) {
          this.restoreAddressData[index] = data
          this.addressRequestsMap[data.address] = false
        }
      }
    },
    formattedSendData() {
      if (this.total <= (this.currentPage - 1) * this.perPage && this.currentPage !== 1) {
        this.currentPage = this.currentPage - 1
      }

      return {
        count: this.perPage,
        address: this.enableSearchParams ? this.address : undefined,
        skip: (this.currentPage - 1) * this.perPage,
      }
    },
    uploadBulkHandler(data) {
      this.currentPage = 1
      this.addBulkData = data
      this.showAddBulkModal = true
      this.loadData()
    },
    openInNewTab(val, routeName = 'analytics') {
      localStorage.removeItem('caseId')
      const { href } = this.$router.resolve({ name: routeName, query: { address: val, type: this.coinData.key } })
      window.open(href, '_blank')
    },
    countChange() {
      this.loadData()
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
    },
  },
}
</script>

<style>
.pp-page__monitoring {
  display: flex;
  flex-direction: column;
  height: 100dvh;
}
.pp-page__monitoring .gl-button {
  text-transform: none;
}
.pp-page__monitoring-actions {
  display: flex;
  align-items: center;
  height: 32px;
}
.pp-page__monitoring .pp-pagination-table {
  padding: 16px 0 0 0;
}

.pp-monitoring__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  backdrop-filter: blur(4px);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
}

/* Monitoring header */
.pp-monitoring__header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  padding: 24px;
  background-color: var(--white);
  border-radius: 12px;
}

.pp-monitoring__header-coin-select {
  max-width: 100px;
  min-width: 100px;
}
.pp-monitoring__header-coin-select .v-select {
  margin-right: 0 !important;
  border-radius: 6px;
}
.pp-monitoring__header-coin-select .vs__dropdown-toggle {
  height: 32px;
  border-radius: 6px;
}
.pp-monitoring__header-coin-select .coin-select-base__dark .vs__dropdown-toggle {
  min-width: 0 !important;
}

.pp-monitoring__header-search {
  flex: 1 0 auto;
}
.pp-monitoring__header-search .gl-search-box {
  gap: 16px;
}
.pp-monitoring__header-search .gl-search-box .gl-input__input {
  min-width: 100px !important;
  border-radius: 6px;
}
.pp-monitoring__header-search .gl-search-box .gl-button {
  width: 140px;
}

.pp-monitoring__header-requests {
  padding-left: 8px;
}

/* Monitoring content */
.pp-monitoring__content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: var(--white);
  border-radius: 12px;
  position: relative;
}

/* PP Table */
.pp-table {
  flex: 1 0 auto;
  overflow: auto !important;
}
.pp-table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.pp-monitoring__table-score {
  max-width: 40px;
  min-width: max-content;
}
.pp-monitoring__table-profile-flags {
  min-width: max-content;
}
.pp-monitoring__table-first-seen,
.pp-monitoring__table-last-seen,
.pp-monitoring__table-number-of-txs,
.pp-monitoring__table-balance,
.pp-monitoring__table-amount-received {
  min-width: max-content;
  text-align: right;
}
.pp-monitoring__table-cntrls {
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 48px;
  min-width: 48px;
}
.pp-monitoring__table-cntrls .gl-icon,
.pp-monitoring__table-cntrls .gl-menu-item {
  display: block;
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
}
.pp-table .o-table__th {
  padding: 0 40px 16px 0 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: var(--space-cadet) !important;
  text-transform: none !important;
  white-space: nowrap !important;
}
.pp-table .o-table__th:nth-child(4),
.pp-table .o-table__th:nth-child(5),
.pp-table .o-table__th:nth-child(6),
.pp-table .o-table__th:nth-child(7),
.pp-table .o-table__th:nth-child(8) {
  text-align: right;
}
.pp-table .o-table__td {
  padding: 8px 40px 8px 0 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--space-cadet) !important;
  text-transform: none !important;
}
.pp-table .o-table__td:last-child {
  width: 48px;
  min-width: 48px;
  padding: 8px 0 8px 0 !important;
}
.pp-page__monitoring .pp-table .o-table__th:first-child,
.pp-page__monitoring .pp-table .o-table__td:first-child {
  width: 80px !important;
  max-width: 80px !important;
}
.pp-date__time,
.pp-monitoring__table-balance-price,
.pp-monitoring__table-amount-received-price {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px !important;
  color: var(--dark-grey-6-e) !important;
}

@media (max-width: 1399px) {
  .pp-table .o-table__th {
    padding: 0 16px 16px 0 !important;
  }
  .pp-table .o-table__td {
    padding: 8px 16px 8px 0 !important;
    font-size: 12px !important;
    line-height: 16px !important;
  }
  .pp-date__time,
  .pp-monitoring__table-balance-price,
  .pp-monitoring__table-amount-received-price {
    font-size: 10px !important;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1200px) {
  select, textarea, input {
    font-size: 14px !important;
  }
}
@media (max-width: 767px) {
  .pp-page__monitoring {
    padding-top: 44px;
    padding-bottom: 40px;
  }
  .pp-monitoring__header {
    gap: 8px 16px;
    margin-bottom: 10px;
    margin-left: -10px;
    margin-right: -10px;
    padding: 8px 18px;
    border-radius: 0;
  }
  .pp-monitoring__header-requests {
    padding-left: 0;
  }

  .pp-monitoring__content {
    padding: 16px 8px;
  }

  .pp-table .o-table__td {
    padding: 4px 16px 4px 0 !important;
  }

  .m-fixed-bottom-wrapper {
    width: 100%;
    position: fixed;
    bottom: 10px;
    left: 0;
    padding: 0 10px;
    z-index: 1;
  }
}
@media (max-width: 579px) {
  .pp-monitoring__header {
    flex-wrap: wrap;
  }
  .pp-monitoring__header-coin-select {
    max-width: 100%;
    flex: 1 0 auto;
    order: 1;
  }
  .pp-monitoring__header-search {
    order: 3;
    min-width: 100%;
  }
  .pp-monitoring__header-requests {
    flex: 0 0 auto;
    order: 2;
  }
}
</style>

